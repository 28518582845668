import appIcon from "./../assest/image/astropet.png";
import homeIcon from "../assest/image/main-dark.png";
import leaderIcon from "../assest/image/iconoir_leaderboard-star.png";
import friendIcon from "../assest/image/Friends.png";
import twitterIcon from "../assest/image/twater.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Logo from "../assest/image/LOGO.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import {useTelegram} from "./../hooks/useTelegram";
const Home = () =>{
    const [active1, setActive1] = useState(false);
    const [active2, setActive2] = useState(false);
    const [active3, setActive3] = useState(false);
    const [active4, setActive4] = useState(false);
    const { tg } = useTelegram();

    console.log({tg});
    const settings = {
        dots: true,
        arrows : false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
    return(<>
        <div className="container text-center mt-3">
        <div className="justify-content-center">
            <img src={Logo} alt="Astron" className="img-fluid" style={{ width : '150px'}}  />
        </div>
        <div className="ledbod-4 pt-3">
            <div className=" row align-items-center" style={{backgroundColor:'#37255E'}} >
                <div className="col-4"><img src={appIcon} alt="" className="img-fluid" width="" /></div>
                <div className="col-8">
                    <div className="" style={{fontSize : '55px'}} >3,572 CP</div>
                </div>
            </div>
        </div>

        <div className="w3-content w3-display-container pt-3 mb-5" style={{maxWidth: "800px"}} >

        <Slider {...settings}>
      <div>
        <div className="text-left p-3 mySlides" style={{backgroundColor : '#37255E', borderRadius:'14px', height:"114px"}} >
            <p className="m-0 font-size">BE A MEMBER</p>
            <p className="font-size m-0">Join to AstroPet TG Chat</p>
            <button type="button " className="btn join fw-bold rounded-pill">JOIN</button>
        </div>
      </div>
      <div>
      <div className="text-left p-3 mySlides" style={{backgroundColor : '#37255E', borderRadius:'14px', height:"114px"}}>
                <p className="m-0 font-size">NEW TASKS COMING SOON </p>
            </div>

      </div>
    
    </Slider>
     </div>

        <div className="text-left mt-2">
            <h2>Tasks</h2>

            <div className="row justify-content-center align-items-center g-2">
                <div className="col-2"><img src={twitterIcon} alt="..." /></div>
                <div className="col-7  p-0">Subscribe to AstroPet on x.com</div>
                <div className="col-2">
                    {active1 ? <><button type="button" onClick={()=>setActive1(false)} id="btn_check" className=" start fw-bold rounded-pill">Check</button></> : <button type="button" onClick={()=>setActive1(true)} id="btn_start" className=" start fw-bold rounded-pill">Start</button>}
                   
                </div>
            </div>

            <div className="row justify-content-center align-items-center g-2">
                <div className="col-2"><img src={twitterIcon} alt="..." /></div>
                <div className="col-7  p-0">Subscribe to AstroPet channel</div>
                <div className="col-2">
                {active2 ? <><button type="button" onClick={()=>setActive2(false)} id="btn_check" className=" start fw-bold rounded-pill">Check</button></> : <button type="button" onClick={()=>setActive2(true)} id="btn_start" className=" start fw-bold rounded-pill">Start</button>}
                </div>
            </div>

            <div className="row justify-content-center align-items-center g-2">
                <div className="col-2"><img src={twitterIcon} alt="..." /></div>
                <div className="col-7  p-0">Tweet AST to Bybit on x.com</div>
                <div className="col-2">
                {active3 ? <><button type="button" onClick={()=>setActive3(false)} id="btn_check" className=" start fw-bold rounded-pill">Check</button></> : <button type="button" onClick={()=>setActive3(true)} id="btn_start" className=" start fw-bold rounded-pill">Start</button>}
                </div>
            </div>

            <div className="row justify-content-center align-items-center g-2">
                <div className="col-2"><img src={twitterIcon} alt="..." /></div>
                <div className="col-7  p-0">Subscribe to AstroPet on x.com</div>
                <div className="col-2">
                {active4 ? <><button type="button" onClick={()=>setActive4(false)} id="btn_check" className=" start fw-bold rounded-pill">Check</button></> : <button type="button" onClick={()=>setActive4(true)} id="btn_start" className=" start fw-bold rounded-pill">Start</button>}
                </div>
            </div>

        </div>

        <div className="text-left reward-cont pt-2">
            <h2>Your rewards</h2>

            <div className="row justify-content-center align-items-center g-2">
                <div className="col-2"><img src={twitterIcon} alt="..."/></div>
                <div className="col-5 p-0">Account age</div>
                <div className="col-4 text-right  ">
                    <div className="" ><p style={{fontWeight : 700, padding: '0px', margin: '0px'}} >+3000 CP</p></div>
                </div>
            </div>

            <div className="row justify-content-center align-items-center g-2">
                <div className="col-2"><img src={twitterIcon} alt="..." /></div>
                <div className="col-5 p-0">Telegram Premium</div>
                <div className="col-4 text-right  ">
                    <div className="" ><p style={{fontWeight : 700, padding: '0px', margin: '0px'}}>+572   CP</p></div>
                </div>
            </div>

          
        </div>

        <nav className="navbar navbar-expand fixed-bottom navbar-light footer">
            <ul className="navbar-nav nav-justified w-100">
                <li className="nav-item">
                <Link className="nav-link bg-light" to={"/home"}>
                    <img src={homeIcon} alt="Main" />
                    <p className="mb-0">Home</p>
                </Link>
                    
                </li>
                <li className="nav-item">
                <Link className="nav-link text-light" to={"/leader-board"}>
                    <img src={leaderIcon} alt="Upgrades" width="36px" height="46px" />
                    <p className="mb-0">Leaderboard</p>
                </Link>
                   
                </li>
               
                <li className="nav-item">
                <Link className="nav-link text-light" to={"/friends"}>
                    <img src={friendIcon} alt="Games" width="46px" height="46px" />
                    <p className="mb-0">Friends</p>
                </Link>
                   
                </li>
            </ul>
        </nav>
        
    </div>


    </>);
}
export default Home;