import { toast } from "react-toastify"
import * as types from "./types"

// signup user
export const signupRequest = (params) => ({
  type: types.SIGNUP_REQUEST,
  payload: params
})
export const signupSuccess = (data, message) => {
  return (dispatch) => {
   // toast.success(message)
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: { data }
    })
  }
}
export const signupFail = (error) => {
  return (dispatch) => {
    toast.error(error)
    dispatch({
      type: types.SIGNUP_FAIL,
      payload: { error }
    })
  }
}

export const loginRequest = (params) => {
  
  return (dispatch) => {
    dispatch({
      type: types.LOGIN_REQUEST,
      payload: params
    })
  }
}

export const loginSuccess = (data, message) => {
  return (dispatch) => {
    
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: { data }
    })
  }
}

export const loginFail = (error) => {
  return (dispatch) => {
    toast.error(error)
    dispatch({
      type: types.LOGIN_FAIL,
      payload: { error }
    })
  }
}
//verify user
export const verifyRequest = (token) => {
  return (dispatch) => {
    dispatch({
      type: types.VERIFY_REQUEST,
      payload: { token }
    })
  }
}

export const verifySuccess = (data, message) => {
  return (dispatch) => {
    console.log(data)
    toast.success(message)
    dispatch({
      type: types.VERIFY_SUCCESS,
      payload: { message }
    })
  }
}

export const verifyFail = (error) => {
  return (dispatch) => {
    toast.error(error)
    dispatch({
      type: types.VERIFY_FAIL,
      payload: { error }
    })
  }
}

export const referenceAddRequest = (params) => {
  console.log({params});
  return (dispatch) => {
    dispatch({
      type: types.USER_REFERENCE_ADD_REQUEST,
      payload: params
    })
  }
}

export const referenceAddSuccess = (data) => {
  return (dispatch) => {
    
    dispatch({
      type: types.USER_REFERENCE_ADD_SUCCESS,
      payload: { data }
    })
  }
}

export const referenceAddFail = (error) => {
  return (dispatch) => {
    toast.error(error)
    dispatch({
      type: types.USER_REFERENCE_ADD_FAIL,
      payload: { error }
    })
  }
}