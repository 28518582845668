import Logo from "../assest/image/LOGO.png"
import LOGO2 from "../assest/image/LOGO.svg" 
import Image from "../assest/image/LOGO.png"
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { loginRequest } from "./../redux/actions/AuthAction";
import { useState, useEffect } from "react";
import {useTelegram} from "./../hooks/useTelegram";
import { useNavigate } from "react-router-dom";
const First = (props) =>{
    const { loginIn, userdata } = props;
    const navigate = useNavigate();
    const { tg } = useTelegram();
    const [user, setUser] = useState([]);
    useEffect(()=>{
        setUser(tg?.initDataUnsafe?.user);
    },[tg]);
    useEffect(()=>{
        if(user?.id){
           
         loginIn({telegram_id : user?.id});
        //loginIn({telegram_id : 1211076528});
            
        }
    },[user]);
    useEffect(()=>{
       if(userdata){
        setTimeout(navigate("/home"), 5000);
       // navigate("/upgrade");
       }
    },[userdata]);
    console.log(tg)
    return(
        <>
             <div className="container text-center mt-3">
        <div className="justify-content-center">

            {/* <img src={Logo} alt="Astron" className="img-fluid logo"  /> */}
            
            <div className="space">
            <img src={LOGO2} alt="Astron" className="img-fluid main-logo"  /> 
                {/* <img src={Image} className="" alt="..." /> */}
                {/* {LOGO2} */}
            </div>
        </div>
        <p>Hey  APET’er!<br />
            You’ve been in Telegram for a while,<br />
            it’s time to get rewarded!’</p>
            
        <Link className="btn btn-light click" to={"/second"} >Wow, let’s go!</Link>

    </div>
        </>
    )
};
const mapStateToProps = ({ app }) => ({
    userdata : app.user
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loginIn: loginRequest,
    }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(First);