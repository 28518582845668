import cancelImg from "./../assest/image/cancel.png";
import logoImg from "./../assest/image/LOGO.png";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { signupRequest, referenceAddRequest } from "./../redux/actions/AuthAction";
import {useTelegram} from "./../hooks/useTelegram";
import { useState, useEffect } from "react";
import {Base64} from 'js-base64';
import { reference } from "../redux/services/AuthService";
const Fifth = (props) =>{
    const { signup, userdata, referenceReq } = props;
    const { tg } = useTelegram();
    const [user, setUser] = useState([]);
    const [ref, setRef] = useState();
    const [refId, setRefId] = useState('');
    useEffect(()=>{
        setUser(tg?.initDataUnsafe?.user);
        setRef(tg?.initDataUnsafe?.start_param); 
    },[tg]);
    useEffect(()=>{
       
        signup({
            telegram_id : user?.id,
            username : user?.username,
            first_name : user?.first_name,
            last_name : user?.last_name,
            ref_tele_id: ref ? Base64.decode(ref) : '',

        });
    },[user]);
    // useEffect(()=>{
    //     if(ref){
    //         setRefId(Base64.decode(ref));
    //         //referenceReq({user_tele_id : user?.id, ref_tele_id :Base64.decode(ref)});
    //     }
    // },[ref])
    // console.log({ref, user})
    return(<>
     
        <div className="container text-center mt-3">
        	<div className=" row ">
                <div className="col-2"><img src={cancelImg} alt="" className="img-fluid" width="" /></div>
                <div className="col-8 "> <img src={logoImg} alt="Astron" className="img-fluid logo"  /></div>
                <div className="col-2"></div>
            </div>

            <div className="row row-cols-3 row-cols-lg-1 g-3 g-lg-3 p-3 justify-content-center">
                <div className="col box ">
                </div>
                <div className="col box">
                </div>
                <div className="col box active-tab">
                </div>

            </div>

            <h2 className="pt-3">You are amazing!</h2>
            <p className="font-size">Here is your AstroPet CP reward</p>

            <p className="font-1">3,572</p>

            <div className="footer">
                <p className="font-size pt-3">Thanks for your time on Telegram.</p>
                <Link className="btn btn-light click" to={"/home"}>Continue</Link>
            </div>
        </div>
    </>);
}
const mapStateToProps = ({ app }) => ({
    userdata : app.user
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        signup : signupRequest,
        referenceReq : referenceAddRequest,
    }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Fifth);