import * as types from "./types";

export const getLeaderboardRequest = () => {
    
    return (dispatch) => {
        dispatch({
            type: types.GET_LEADERBOARD_REQUEST,
            payload: {}
        })
    }
  }
  
export const getLeaderboardSuccess = (data) => {
    return (dispatch) => {
        
        dispatch({
            type: types.GET_LEADERBOARD_SUCCESS,
            payload: { data }
        })
    }
}
  
export const getLeaderboardFail = (error) => {
    return (dispatch) => {
        dispatch({
        type: types.GET_LEADERBOARD_FAIL,
        payload: { error }
        })
    }
}

export const getUserWalletRequest = (params) => {
    
    return (dispatch) => {
        dispatch({
            type: types.GET_USER_WALLET_REQUEST,
            payload: params
        })
    }
  }
  
export const getUserWalletSuccess = (data) => {
    return (dispatch) => {
        
        dispatch({
            type: types.GET_USER_WALLET_SUCCESS,
            payload: { data }
        })
    }
}
  
export const getUserWalletFail = (error) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_USER_WALLET_FAIL,
            payload: { error }
        })
    }
}


export const getUSerReferenceRequest = (params) => {
    
    return (dispatch) => {
        dispatch({
            type: types.GET_USER_REFERENCE_REQUEST,
            payload: params
        })
    }
  }
  
export const getUSerReferenceSuccess = (data) => {
    return (dispatch) => {
        
        dispatch({
            type: types.GET_USER_REFERENCE_SUCCESS,
            payload: { data }
        })
    }
}
  
export const getUSerReferenceFail = (error) => {
    return (dispatch) => {
        dispatch({
            type: types.GET_USER_REFERENCE_FAIL,
            payload: { error }
        })
    }
}