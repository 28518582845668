import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";
import * as AuthService from "../services/AuthService";
import * as AuthAction from "../actions/AuthAction";

export default function* sagas() {
  function* singup(action) {
    try {
      const params = action.payload || {};
      const res = yield call(AuthService.singup, params);
      if (res && res.success) {
        yield put(AuthAction.signupSuccess(res.data?.token, res.message));
      } else {
        yield put(
          AuthAction.signupFail(res?.message || "Invalid request")
        );
      }
    } catch (error) {
      console.log(error);
      yield put(
        AuthAction.signupFail(
          error?.response?.data?.message || "Invalid request"
        )
      );
    }
  }
  
  function* login(action) {
    try {
      const params = action.payload || {};
      const res = yield call(AuthService.login, params);
      if (res && res.data) {
        yield put(AuthAction.loginSuccess(res, res.message));
      } else {
        yield put(
          AuthAction.loginFail(res?.message || "Invalid request")
        );
      }
    } catch (error) {
      console.log(error);
      yield put(
        AuthAction.loginFail(
          error?.response?.data?.message || "Invalid request"
        )
      );
    }
  }
  function* referenceAddRequest(action) {
    try {
      const params = action.payload || {};
      const res = yield call(AuthService.reference, params);
      if (res && res.data) {
        yield put(AuthAction.referenceAddSuccess(res, res.message));
      } else {
        yield put(
          AuthAction.referenceAddFail(res?.message || "Invalid request")
        );
      }
    } catch (error) {
      console.log(error);
      yield put(
        AuthAction.referenceAddFail(
          error?.response?.data?.message || "Invalid request"
        )
      );
    }
  }
  yield takeLatest(types.SIGNUP_REQUEST, singup);
  yield takeLatest(types.LOGIN_REQUEST, login);
  yield takeLatest(types.USER_REFERENCE_ADD_REQUEST, referenceAddRequest);
}
