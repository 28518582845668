import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { useEffect, useState } from "react";
import homeIcon from "../assest/image/main.png";
import leaderIcon from "../assest/image/iconoir_leaderboard-star-dark.png";
import friendIcon from "../assest/image/Friends.png";
import Logo from "../assest/image/LOGO.png";
import cancel from "../assest/image/cancel.png";
import appIcon from "../assest/image/astropet.png";
import tokenIcon from "../assest/image/Token-1.png";
import { getLeaderboardRequest, getUserWalletRequest } from "../redux/actions/UserAction";
import { useTelegram } from "../hooks/useTelegram";
const LeaderBoard = (props) =>{
    const { getLeaderBoard, leaderBoards, getUserWallet, userWallet} = props;
    const [leaderData, setLeaderData] = useState([]);
    const [wallet, setWallet] = useState([]);
    const { tg } = useTelegram();
    const [user, setUser] = useState([]);
    useEffect(()=>{
        setUser(tg?.initDataUnsafe?.user);
    },[tg]);
    useEffect(()=>{
       getLeaderBoard();
       getUserWallet(user?.id)
    },[user]);
    useEffect(()=>{
       
        setLeaderData(leaderBoards?.data || []);
        setWallet(userWallet?.data || [])
    },[leaderBoards, userWallet]);
    return(<>
         <div className="container text-center reward-cont mt-3">
        <div className=" row ">
            <div className="col-2"><Link to="/home"><img src={cancel} alt="" className="img-fluid" /></Link></div>
            <div className="col-8"> <img src={Logo} alt="Astron" className="img-fluid" style={{width:'150px'}} /></div>
            <div className="col-2"></div>
        </div>



        <center>
            <div className="row align-items-center g-2 list mb-2" style={{ paddingBottom:'0px'}}>
                <div className="col"><img src={appIcon} className="" alt="..." width="61px" height="50px"/></div>
                <div className="col">
                    <p>{wallet?.user?.username}</p>
                </div>
                <div className="col">{wallet?.cp}<img src={tokenIcon} alt="" style={{width:'14px', height:'14px'}} /></div>
            </div>

            {leaderData?.length > 0 && leaderData?.map((leader, index)=>{
                return(
                    <div className="leaderboard-item d-flex justify-content-between align-items-center" key={index}>

                    <div className="user-data"><span style={{backgroundColor:'#37255E', width:'38px', height:'38px'}}></span>
    
                        <div className="row g-2" style={{flexDirection: 'column'}}>
                            <div className="col">{leader?.user?.username}</div>
                            <div className="col">{leader?.cp} <img src={tokenIcon} alt="" style={{width:'14px', height:'14px'}} />
                            </div>
    
                        </div>
    
                    </div>
                    <div className="user-num">
                        <h1>{index+1}</h1>
                    </div>
                </div>
                )
            })}

           

           

            
            

        </center>

        <nav className="navbar navbar-expand fixed-bottom navbar-light footer">
            <ul className="navbar-nav nav-justified w-100">
                <li className="nav-item">
                <Link className="nav-link text-light" to={"/home"}>
                    <img src={homeIcon} alt="Main" />
                    <p className="mb-0">Home</p>
                </Link>
                    
                </li>
                <li className="nav-item">
                <Link className="nav-link bg-light" to={"/leader-board"}>
                    <img src={leaderIcon} alt="Upgrades" width="36px" height="46px" />
                    <p className="mb-0">Leaderboard</p>
                </Link>
                   
                </li>
               
                <li className="nav-item">
                <Link className="nav-link text-light" to={"/friends"}>
                    <img src={friendIcon} alt="Games" width="46px" height="46px" />
                    <p className="mb-0">Friends</p>
                </Link>
                </li>
            </ul>
        </nav>
	</div>
     
    </>);
}
const mapStateToProps = ({ app, user }) => ({
    userdata : app.user,
    leaderBoards : user?.leader,
    userWallet : user?.wallet
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getLeaderBoard: getLeaderboardRequest,
        getUserWallet: getUserWalletRequest,
    }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(LeaderBoard);