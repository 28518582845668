import { Link } from "react-router-dom";
import homeIcon from "../assest/image/main.png";
import leaderIcon from "../assest/image/iconoir_leaderboard-star.png";
import friendIcon from "../assest/image/Friends-dark.png";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Logo from "../assest/image/LOGO.png";
import cancel from "../assest/image/cancel.png";
import iconLeft from "../assest/image/astropet.png";
import iconRight from "../assest/image/astropet.png";
import tokenIcon from "../assest/image/Token-1.png";
import {useTelegram} from "./../hooks/useTelegram";
import { getUSerReferenceRequest } from "../redux/actions/UserAction";
import {Base64} from 'js-base64';
import sign from "jwt-encode";

import ReactJson from 'react-json-view';
import { useEffect, useState } from "react";
const Friend = (props) =>{
    const { getUserRef, userReference } = props;
    const secret = 'secret';
    const { tg } = useTelegram();
    
    
    const [user, setUser] = useState([]);
    const [refData, setRefData] = useState([]);
    const [ref, setRef] = useState();
    useEffect(()=>{
        setUser(tg?.initDataUnsafe?.user);
        setRef(tg?.initDataUnsafe?.start_param);
    },[tg]);
    const invitationHandhler = () =>{
        const data = {
            id: user?.id,
           
        };
        const jwt = Base64.encodeURI(user?.id);;
        tg.openLink(`https://telegram.me/share/url?url=https://t.me/astropetBot/astroPet?startapp=${jwt}`);
        console.log(tg.openLink);
    }
    useEffect(()=>{
        getUserRef(user?.id);
    },[user]);

    useEffect(()=>{
        setRefData(userReference?.data || [])
    },[userReference]);
    return(<>
    
        <div className="container text-center reward-cont mt-3">
        <div className=" row ">
            <div className="col-2"><Link to="/home"><img src={cancel} alt="" className="img-fluid"/></Link></div>
            <div className="col-8 "> <img src={Logo} alt="Astron" className="img-fluid" style={{width:'150px'}} /></div>
            <div className="col-2"></div>
        </div>



        <center>

            <h3 className="p-4">Invite friends and <br/>
                get more CP</h3>


            <div className="row align-items-center g-2 list " style={{ paddingBottom:'0px'}} onClick={invitationHandhler}>
                <div className="col"><img src={iconLeft} className="" alt="..."/></div>
                <div className="col">
                <p style={{width:'145px'}} >Invite friends</p>
                </div>
                <div className="col"><img src={iconRight} className="" alt="..."/></div>
            </div>
            {refData?.length > 0 && refData?.map((ref, index)=>{
                return(
                    <div className="leaderboard-item d-flex justify-content-between align-items-center" key={index}>
                        <div className="user-data"><span style={{backgroundColor:'#37255E', width:'38px', height:'38px'}} ></span>

                            <div className="row g-2" style={{flexDirection: 'column'}} >
                                <div className="col">{ref?.username}</div>
                                <div className="col">{ref?.wallet?.cp} <img src={tokenIcon} alt="" style={{width:'14px', height:'14px'}} />
                                </div>

                            </div>

                        </div>
                        <div className="user-num">
                            <h1>{index + 1}</h1>
                        </div>
                    </div>

                );
            })}
            
            
        </center>

        <nav className="navbar navbar-expand fixed-bottom navbar-light footer">
            <ul className="navbar-nav nav-justified w-100">
            <li className="nav-item">
                <Link className="nav-link text-light" to={"/home"}>
                    <img src={homeIcon} alt="Main" />
                    <p className="mb-0">Home</p>
                </Link>
                    
                </li>
                <li className="nav-item">
                <Link className="nav-link text-light" to={"/leader-board"}>
                    <img src={leaderIcon} alt="Upgrades" width="36px" height="46px" />
                    <p className="mb-0">Leaderboard</p>
                </Link>
                   
                </li>
               
                <li className="nav-item">
                <Link className="nav-link bg-light" to={"/friends"}>
                    <img src={friendIcon} alt="Games" width="46px" height="46px" />
                    <p className="mb-0">Friends</p>
                </Link>
                   
                </li>
            </ul>
        </nav>
        </div>
    </>
    );
};
const mapStateToProps = ({ app, user }) => ({
    userdata : app.user,
    userReference : user?.reference,
    userWallet : user?.wallet
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getUserRef: getUSerReferenceRequest,
    }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(Friend);