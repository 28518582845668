import { useEffect } from 'react';
import {useTelegram} from "./hooks/useTelegram";
import 'bootstrap/dist/css/bootstrap.min.css';
import Start from './pages/Start';
import Second from './components/Second';
import Third from './components/Third';
import Fourth from './components/Fourth';
import Fifth from './components/Fifth';
import First from './components/First';
import Home from './pages/Home';
import Friend from './pages/Friend';
import LeaderBoard from './pages/LeaderBoard';
import ReactJson from 'react-json-view';
import Page from './pages/Page';
import './App.css';
import Upgrade from './pages/Upgrade';
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  const webapp = window.Telegram.WebApp?.getItem;
  const { tg } = useTelegram();
  useEffect(() => {
   // tg.ready();
}, [])
  console.log({ webapp });
  return (
    //  <ReactJson src={tg} />
    <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<Layout />}> */}
        <Route index element={<First />} />
        <Route path="second" element={<Second />} />
        <Route path="third" element={<Third />} />
        <Route path="fourth" element={<Fourth />} />
        <Route path="fifth" element={<Fifth />} />
        <Route path="home" element={<Home />} />
        <Route path="friends" element={<Friend />} />
        <Route path="leader-board" element={<LeaderBoard />} />
      {/* </Route> */}
    </Routes>
  </BrowserRouter>
  );
}

export default App;
