import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";
import * as GameService from "../services/GameService";
import * as GameAction from "../actions/GameAction";

export default function* sagas() {
    function* setScoreRequest(action) {
      try {
        const params = action.payload || {};
        const res = yield call(GameService.setScore, params);
        if (res && res.success) {
          yield put(GameAction.setScoreSuccess(res.data, res.message));
        } else {
          yield put(
            GameAction.setScoreFail(res?.message || "Invalid request")
          );
        }
      } catch (error) {
        console.log(error);
        yield put(
            GameAction.setScoreFail(
            error?.response?.data?.message || "Invalid request"
          )
        );
      }
    }
    
    
    yield takeLatest(types.USER_GAME_SCORE_SET_REQUEST, setScoreRequest);
  }
  