import Logo from "./../assest/image/LOGO.png";
import { Link } from "react-router-dom";
import {useTelegram} from "./../hooks/useTelegram";
import { useEffect, useState } from "react";
import getAge  from "./../hooks/idage";
const Third = () =>{
    const { tg } = useTelegram();
    const [user, setUser] = useState();
    const [year, setYear] = useState(0);
    useEffect(()=>{
        setUser(tg?.initDataUnsafe?.user);
    },[tg]);
    useEffect(()=>{
        if(user){

            console.log("Date : ", getAge(user?.id))
            var years = 2024 - getAge(user?.id);
            setYear(years);
        }
    },[user]);
    return(<>
   
     <div className="container text-center mt-3">
        <div className="justify-content-center">

            <img src={Logo} alt="Astron" className="img-fluid logo" />
        </div>

        <div className="row row-cols-3 row-cols-lg-1 g-3 g-lg-3 p-3 justify-content-center">
            <div className="col box active-tab">
            </div>
            <div className="col box">
            </div>
            <div className="col box">
            </div>

        </div>

        <h2 className="pt-3">Elite member!</h2>
        <p className="font-size">You’ve joined Telegram!</p>

        <p style={{fontSize : '130px'}} >{year}</p>
        <h2>years ago</h2>

        <div className="space-page1">
            <p className="font-size">Your account number is #{user?.id}.</p>
            <Link className="btn btn-light click" to={"/fourth"}>Continue</Link>
        </div>
    </div>
    </>)
};
export default Third;