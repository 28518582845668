import * as types from '../actions/types';

const initialState = {
    loader: false,
    leader: null,
    wallet: null,
    reference: null,
   
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
      
        case types.GET_LEADERBOARD_REQUEST:
            return { ...state,  leader : false, loader: true };
        case types.GET_LEADERBOARD_SUCCESS:
            return { ...state, leader : action.payload, loader: false };
        case types.GET_LEADERBOARD_FAIL:
            return { ...state, loader: false, };
        case types.GET_USER_WALLET_REQUEST:
            return { ...state,  wallet : false, loader: true };
        case types.GET_USER_WALLET_SUCCESS:
            return { ...state, wallet : action.payload, loader: false };
        case types.GET_USER_WALLET_FAIL:
            return { ...state, loader: false, };
        case types.GET_USER_REFERENCE_REQUEST:
            return { ...state,  reference : false, loader: true };
        case types.GET_USER_REFERENCE_SUCCESS:
            return { ...state, reference : action.payload, loader: false };
        case types.GET_USER_REFERENCE_FAIL:
            return { ...state, loader: false, };
                

        default:
            return state;
    }
};

export default reducer;