import { all } from 'redux-saga/effects';
import AuthSaga from "./AuthSaga"
import GameSaga from "./Gamesaga"
import UserSaga from "./UserSaga"
export default function* sagas() {
    yield all([
        AuthSaga(),
        GameSaga(),
        UserSaga()
    ]);
}