import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";
import * as UserService from "../services/UserService";
import * as UserAction from "../actions/UserAction";

export default function* sagas() {
    function* getLeaderboardRequest(action) {
      try {
        const params = action.payload || {};
        console.log("Working.")
        const res = yield call(UserService.getLeaders);
        if (res && res.success) {
          yield put(UserAction.getLeaderboardSuccess(res.data, res.message));
        } else {
          yield put(
            UserAction.getLeaderboardFail(res?.message || "Invalid request1")
          );
        }
      } catch (error) {
        console.log({error});
        yield put(
            UserAction.getLeaderboardFail(
            error?.response?.data?.message || "Invalid request2"
          )
        );
      }
    }
    
    function* getUserWalletRequest(action) {
        try {
          const params = action.payload || {};
          const res = yield call(UserService.getUserWallet, params);
          if (res && res.success) {
            yield put(UserAction.getUserWalletSuccess(res.data, res.message));
          } else {
            yield put(
              UserAction.getUserWalletFail(res?.message || "Invalid request1")
            );
          }
        } catch (error) {
          console.log({error});
          yield put(
              UserAction.getUserWalletFail(
              error?.response?.data?.message || "Invalid request2"
            )
          );
        }
    }

    function* getUSerReferenceRequest(action) {
        try {
            const params = action.payload || {};
            const res = yield call(UserService.getUserReference, params);
            if (res && res.success) {
                yield put(UserAction.getUSerReferenceSuccess(res.data, res.message));
            } else {
                yield put(
                    UserAction.getUSerReferenceFail(res?.message || "Invalid request1")
                );
            }
        } catch (error) {
            yield put(
                UserAction.getUSerReferenceFail(
                    error?.response?.data?.message || "Invalid request2"
                )
            );
        }
    }

    
    yield takeLatest(types.GET_LEADERBOARD_REQUEST, getLeaderboardRequest);
    yield takeLatest(types.GET_USER_WALLET_REQUEST, getUserWalletRequest);
    yield takeLatest(types.GET_USER_REFERENCE_REQUEST, getUSerReferenceRequest);
}
  