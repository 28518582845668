import * as types from '../actions/types';

const initialState = {
    loader: false,
    user: null,
    token: null,
    isLoggedIn: false,
    isVerified: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.INIT_APP:
            return { ...state, loader: false };
        case types.SIGNUP_REQUEST:
            return { ...state,  user : false, loader: true };
        case types.LOGIN_REQUEST:
            return { ...state, user : false, loader: true };
        case types.USER_REFERENCE_ADD_REQUEST:
            return { ...state, loader: true };
        case types.VERIFY_REQUEST:
            return { ...state, loader: true, isVerified: false }
        case types.SIGNUP_SUCCESS:
            return { ...state, user : action.payload, loader: false };
        case types.LOGIN_SUCCESS:
            return { ...state, user : action.payload, loader: false };
        case types.USER_REFERENCE_ADD_SUCCESS:
            return { ...state, loader: false };
        case types.VERIFY_SUCCESS:
            return {
                ...state,
                ...(action.payload || {}),
                isVerified: true,
                loader: false
            };
        case types.SIGNUP_FAIL:
        case types.VERIFY_FAIL:
        case types.USER_REFERENCE_ADD_FAIL:
        case types.LOGIN_FAIL:
            return { ...state, loader: false, };
        default:
            return state;
    }
};

export default reducer;