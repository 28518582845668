import * as types from "./types";

export const setScoreRequest = (params) => ({
    type: types.USER_GAME_SCORE_SET_REQUEST,
    payload: params
  })
  export const setScoreSuccess = (data, message) => {
    return (dispatch) => {
     // toast.success(message)
      dispatch({
        type: types.USER_GAME_SCORE_SET_SUCCESS,
        payload: { data }
      })
    }
  }
  export const setScoreFail = (error) => {
    return (dispatch) => {
      
      dispatch({
        type: types.USER_GAME_SCORE_SET_FAIL,
        payload: { error }
      })
    }
  }