import cancelImg from "./../assest/image/cancel.png";
import logoImg from "./../assest/image/LOGO.png";
import image1 from "../assest/image/Asset 15.png"
import { Link } from "react-router-dom";
const Fourth = () =>{
    return(<>
        <div className="container text-center mt-3">
            <div className=" row ">
                <div className="col-2"><img src={cancelImg} alt="" className="img-fluid" width="" /></div>
                <div className="col-8 "> <img src={logoImg} alt="Astron" className="img-fluid logo"  /></div>
                <div className="col-2"></div>
            </div>

            <div className="row row-cols-3 row-cols-lg-1 g-3 g-lg-3 p-3 justify-content-center">
                <div className="col box">
                </div>
                <div className="col box active-tab">
                </div>
                <div className="col box">
                </div>

            </div>

            <h2 className="pt-3">Elite member!</h2>
            <p className="font-size">You’ve joined Telegram!</p>


            <div className="space-page2">
                <h2>Premium user</h2>

                <img src={image1} alt="Astron" className="img-fluid" style={{width :'288px'}} />

                <p className="font-size pt-3">Status confirmed</p>
                <Link className="btn btn-light click" to={"/fifth"}>Continue</Link>
            </div>
        </div>
    </>);
}
export default Fourth;