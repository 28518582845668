import * as API from "./Api";

export const singup = (params) => {
  return API.post("/user/register", params);
};

export const login = (params) => {
 
  return API.post("/user/login", params);
};

export const reference = (params) => {
  return API.post("/user/reference", params);
};