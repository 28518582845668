// Init action types
export const INIT_APP = 'INIT_APP';

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAIL = "VERIFY_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const USER_GAME_SCORE_SET_REQUEST = "USER_GAME_SCORE_SET_REQUEST";
export const USER_GAME_SCORE_SET_SUCCESS = "USER_GAME_SCORE_SET_SUCCESS";
export const USER_GAME_SCORE_SET_FAIL = "USER_GAME_SCORE_SET_FAIL";

export const USER_REFERENCE_ADD_REQUEST = "USER_REFERENCE_ADD_REQUEST";
export const USER_REFERENCE_ADD_SUCCESS = "USER_REFERENCE_ADD_SUCCESS";
export const USER_REFERENCE_ADD_FAIL = "USER_REFERENCE_ADD_FAIL";

export const GET_LEADERBOARD_REQUEST = "GET_LEADERBOARD_REQUEST";
export const GET_LEADERBOARD_SUCCESS = "GET_LEADERBOARD_SUCCESS";
export const GET_LEADERBOARD_FAIL = "GET_LEADERBOARD_FAIL";

export const GET_USER_WALLET_REQUEST = "GET_USER_WALLET_REQUEST";
export const GET_USER_WALLET_SUCCESS = "GET_USER_WALLET_SUCCESS";
export const GET_USER_WALLET_FAIL = "GET_USER_WALLET_FAIL";

export const GET_USER_REFERENCE_REQUEST = "GET_USER_REFERENCE_REQUEST";
export const GET_USER_REFERENCE_SUCCESS = "GET_USER_REFERENCE_SUCCESS";
export const GET_USER_REFERENCE_FAIL = "GET_USER_REFERENCE_FAIL";