import * as types from '../actions/types';

const initialState = {
    loader: false,
    game: null,
    gameRes : false
   
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
      
        case types.USER_GAME_SCORE_SET_REQUEST:
            return { ...state,  user : false, loader: true };
        case types.USER_GAME_SCORE_SET_SUCCESS:
            return { ...state, user : action.payload, loader: false };
        case types.USER_GAME_SCORE_SET_FAIL:
            return { ...state, loader: false, };
        default:
            return state;
    }
};

export default reducer;