import * as API from "./Api";
export const getLeaders = () => {
    return API.get("/leader-board");
};

export const getUserWallet = (param) => {
    return API.get(`/user-wallet/${param}`);
};

export const getUserReference = (param) => {
    return API.get(`/user-reference/${param}`);
};